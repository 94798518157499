$(() => {
  enableAdvancedSearchFilter();
  guardVideoPlayer();
  createBurgerMenu();
  hideTitle();
});


/* Do not show the configured site_title. AA uses the site_title for
   the <title> tag which we want, but we do not want to show the
   site_title as a h1, because we've replaced it with a background
   image. We cannot remove it with CSS, because there's the logo in
   the background. */
function hideTitle() {
  $("#site_title").text("");
}

function createBurgerMenu() {
  $('<div id="burger_menu"><span></span></div>').insertAfter("#site_title");

  var burgerMenu = document.getElementById("burger_menu");
  var overlay = document.getElementById("tabs");
  burgerMenu.addEventListener("click", function () {
    this.classList.toggle("close");
    overlay.classList.toggle("overlay");
  });
}

// The AA videos are embedded in an <a> element. On mobile, when
// clicking the video, this redirects to Documents#Show. This function
// prevents the redirect and instead play/pauses the video.
function guardVideoPlayer() {
  $(".admin_videos video").click((e) => {
    const video = e.currentTarget;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
    e.preventDefault();
    return false;
  });
}

function toggleFilter(elem) {
  if ($(elem).text() == "More") {
    $(elem).text("Less");
    $(".collapsed").show("slow");
  } else {
    $(elem).text("More");
    $(".collapsed").hide("slow");
  }
}

function enableAdvancedSearchFilter() {
  const searchForm = "#active_admin_content.with_sidebar #sidebar #new_q";

  // Enable advanced search filter only if there's any optional
  // elements.
  if ($(`${searchForm} div.collapsed`).length) {
    $(".collapsed").hide();

    // Add the more/less button as filter action.
    $('<a href="#" class="clear_filters_btn advanced_search">More</a>')
      .appendTo($`${searchForm} .buttons`)
      .click((e) => {
        toggleFilter(e.currentTarget);
        e.preventDefault();
        return false;
      });
  }
}
