// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";

require("activeadmin-sortable");

import $ from "jquery";
import "select2"; // globally assign select2 fn to $ element
import "select2/dist/css/select2.css"; // optional if you have css loader

import '@alenaksu/json-viewer';

import "./ok_aa_responsive";

import initializeIngestQuality from "./active_admin/ingest_quality";

import { debounce } from 'throttle-debounce';

window.vegaEmbed = require("vega-embed").default

// BEGIN: Lightbox

import { Luminous } from "luminous-lightbox";

$(() => {
  if (document.querySelector("img[data-lightbox]")) {
    new Luminous(document.querySelector("img[data-lightbox]"), {
      sourceAttribute: "src",
    });
  }
});

// END: Lightbox

$(() => {
  $(".select2").select2();
});

$(function () {
  if (document.querySelector(".admin_ingest_quality")) {
    initializeIngestQuality();
  }
});

// workaround for defunct alensuk/json-viewer
$(() => {
  document.querySelectorAll('json-viewer').forEach((x) => x.innerHTML != "" ? x.data = JSON.parse(x.innerHTML) : false)
})

// BEGIN: validate text fields that may contain JSON

function validateJSON(selector) {
  var text = $(selector).val()
  if(text == '') {
    $(selector).removeClass('valid').removeClass('invalid')
  } else {
    try {
      var obj = JSON.parse(text)
      text = JSON.stringify(obj, null, 2)
      $(selector).val(text).addClass('valid').removeClass('invalid')
    }
    catch(err) {
      console.warn(err)
      $(selector).addClass('invalid').removeClass('valid')
    }
  }
}

const validateWith = {search: 'json',
                      subset: 'json',
                      merge: 'json'}

function validate(selector) {
  const methodSelector = selector+'_method'
  const method = $(methodSelector).val()
  if(validateWith[method] == 'json') {
    validateJSON(selector)
  } else {
    console.log('No validation for method:', method)
    $(selector).removeClass('valid').removeClass('invalid')
  }
}

$(() => {
  $('#rule_conditions, #rule_effects')
    .on('change keyup paste',
        debounce(400, (e) => {
          validate('#' + e.target.id)
        }))

  $('#rule_conditions_method').on('change', (e) => {
    validate('#rule_conditions')
  })

  $('#rule_effects_method').on('change', (e) => {
    validate('#rule_effects')
  })

  // initial
  validate('#rule_conditions')
  validate('#rule_effects')
})

// END: validate text fields that may contain JSON

// BEGIN: shortcuts

const shortcuts = {
  autopublish: {label: "Autopublish",
                state: (x) => {
                  if(x.mapping && x.mapping.auto_publish) return "on"
                  if(x.mapping && x.mapping.auto_publish === false) return "off"
                  return "neutral"
                },
                states: {on: {mapping: {auto_publish: true}},
                         off: {mapping: {auto_publish: false}},
                         neutral: (x) => {
                           delete x.mapping.auto_publish;
                           if($.isEmptyObject(x.mapping)) delete x.mapping;
                           return x
                         }}},
  addToNews: {label: "Add To News",
              state: (x) => {
                if(x.mapping && x.mapping.add_to_news) return "on"
                if(x.mapping && x.mapping.add_to_news === false) return "off"
                return "neutral"
              },
              states: {on: {mapping: {add_to_news: true}},
                       off: {mapping: {add_to_news: false}},
                       neutral: (x) => {
                         delete x.mapping.add_to_news;
                         if($.isEmptyObject(x.mapping)) delete x.mapping;
                         return x
                       }}}
}

function handleShortcut(key, e) {
  const text = $('#rule_effects').val()
  var data;
  if(text == '') {
    data = {}
  } else {
    data = JSON.parse(text)
  }
  const state = shortcuts[key].state(data)
  const states = Object.keys(shortcuts[key].states)
  const index =  states.indexOf(state)
  const next = (index + 1) % states.length
  const process = shortcuts[key].states[states[next]]
  var result;
  switch(typeof process) {
  case "function":
    result = process(data)
    break
  case "object":
    result = $.extend(true, data, process)
    break
  }
  $('#rule_effects').val(JSON.stringify(result, null, 2))
}

$(() => {
  $('#rule_effects_method_input').after($('<li><label for="rule_effects_shortcuts" class="label">Effects shortcuts</label><ul id="shortcuts"></ul></li>'))
  $.each(shortcuts, (key, value) => {
    $('#shortcuts').append(
      $('<li><a href="#">' + value.label + '</a></li>').on('click', (e) => {
        e.preventDefault(); handleShortcut(key, e)
      }))
  })

  $('#copy-json').click((e) => {
    navigator.clipboard.writeText($("#json-viewer").text());
    console.log('Copied document JSON to clipboard');
  })

  // Fetch document logs asynchronously.
  $('[aria-controls="logs"]').click((e) => {
    $("#logs_content").html("Fetching logs...");
    const url = $('#logs_content').data('url');

    $.get(url, function(data) {
      $("#logs_content").html(data);
    });
  });

})

// END: shortcuts

// make jquery available on the console
window.$ = $
