const initializeIngestQuality = () => {
  let filterDate = new URL(location.href).searchParams.get("date");

  if (filterDate) {
    filterDate = new Date(filterDate);
  } else {
    // No date has been set in the UI, so the `default_scope` from Rails
    // is active
    filterDate = new Date(default_scope);
  }

  document.querySelector("#timepicker").valueAsDate = filterDate;
  document.querySelector("#datepicker").valueAsDate = filterDate;

  $("#ingest_quality-go").on("click", (e) => {
    const date = $("#datepicker").val();
    const time = $("#timepicker").val();
    const newDateStr = `${date} ${time}`;
    console.log(newDateStr);
    const newDate = new Date(newDateStr).toISOString();
    history.pushState({}, "", `?date=${newDate}`);
    window.location.reload();
  });
}

export default initializeIngestQuality;
